.expertiseTitle {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;    
  text-align: center;
}

.expertiseTitle.ar {
  direction: rtl;
}

.expertiseRotateText{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 28px;
  text-align: center;
  color: #667175;    
}

.expertiseRotate{
  width: 24px;
  height: 16px;
  margin-top: 15px;
  margin-left: 10px;
}

.ourExpertiseWrapper{
  margin-top: 120px;
  margin-bottom: 166px;
}

.expertiseImg{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50px;
  height: 50px;
}

.expertiseText{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;    
  text-align: center;
  color: #667175;
  width: 80%;
  margin: auto;
  margin-top:30px;
}

.breakText{
  width: 50% !important;
}

.breakTextDesktop{
  width: 60% !important;
}

.expertiseText.ar {
  direction: rtl;
}

.cardExpertise{
  margin-top: 5px;
  box-shadow: 0px 2px 8px rgba(55, 61, 63, 0.2);
  width: 277px;
  height: 277px;
}

@media(min-width: 1024px) {
  .cardExpertise{
    margin-left: 10.5px;
    margin-right: 10.5px;
  }
}

@media(min-width: 1300px) {
  .cardExpertise{
    margin-left: 10.5px;
    margin-right: 10.5px;
    width: 413px;
    height: 413px;
  }

  .expertiseTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 96px;
    text-align: center;
  }

  .expertiseFrontContainer {
    margin: auto;
    width: 270px;
  }

  .expertiseFrontContainer.short{
    width: 240px;
  }

  .expertiseBackContainer {
    margin-top: 70px;
  }

  .expertiseText{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    margin-top: 30px;
  }

  .expertiseImg{
    width: 140px;
    height: 140px;
  }
}