.strategyTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;    
    text-align: left;
    color: #FBFBFB;
}

.strategyTitle.ar {
  text-align: right;
  direction: rtl;
}

.strategyTitle-p, .strategyTitle-ul  {
    color: #FBFBFB;
}

.strategyTitle-p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-block-end: unset;
}

.strategyTitle-p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-block-end: unset;
}

.strategyTitle-p span {
  font-weight: bold;
}

.strategyTitle-p.ar {
  text-align: right;
  direction: rtl;
}

.strategyTitle-ul  {
  margin-block-start: unset !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  list-style-type: disc;
}

.strategyTitle-ul span {
  font-weight: bold;
}

.strategyTitle-ul.ar {
  text-align: right;
  direction: rtl;
}

.strategyWrapper {
    padding-top: 88px;
    padding-bottom: 24px;    
}

#strategyComponent .alice-carousel__dots-item.__active{
    background-color:#FFFFFF !important;
    opacity: 1;
}