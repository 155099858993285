.cardWPWrapper {
  position: relative;
  cursor: pointer;
  width: 277px;
  height: 471px;
  box-shadow: 0px 2px 8px rgba(55, 61, 63, 0.2);
  margin-bottom: 5px;
  background: #FFFFFF;
}

.cardWPWrapper .cover {
  background-color: #667175;
  background-repeat: no-repeat;
  background-size: cover;
  height: 160px;
  z-index: -1;
}

.cardWPWrapper .dotgrid {
  object-fit: fill;
  width: 100%;
  z-index: -1;
  margin-bottom: 96px;
}

.cardWPWrapper .dashboard{
  position: absolute;
  height: 295px;
  width: 228px;
  top: 24px;
  left: 24px;
  z-index: 1;
}

.cardWPWrapper .title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #373D3F;
}

.cardWPWrapper .title.ar {
  text-align: right;
  direction: rtl;
}

.cardWPWrapper .description {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #181818;
}

.cardWPWrapper .description.ar {
  text-align: right;
  direction: rtl;
}

.cardWPWrapper .MuiFormLabel-root.Mui-focused {
  color: #F46C00 !important;
}

.cardWPWrapper .MuiInput-underline:after {
  border-bottom: 1px solid #C1C7C9;
}

.cardWPWrapper input[type=submit] {
  cursor: pointer;
  outline: none;
  margin-top: 90px;
  height: 48px;
  width: 218px;
  border: 3px solid #ffb77e !important;
  background-color: #FFFFFF !important;
  box-sizing: border-box;

  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ffb77e !important;
}

.cardWPWrapper .button:hover {
  border: 3px solid #F46C00 !important;
  color: #F46C00 !important;
}

.cardWPWrapper .button:active {
  border: 3px solid #F46C00 !important;
  color: #F46C00 !important;
}

.cardWPWrapper .footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: #667175;
}

.cardWPRotateRight{
  position: absolute;
  right: 8px;
  bottom: 8px;
  width: 24px;
  height: 16px;
}

.invisibleDiv{
  background-color: transparent; 
  display: absolute; 
  top: 0px; 
  left: 0px; 
  width: 100%; 
  height: 100%
}

.cardWPWrapper .actions {
  text-align: center !important;
}

.cardWPWrapper ul {
  list-style-type: none;
  padding-inline-start: unset;
}

@media(min-width: 1024px) {
  .cardWPWrapper {
    margin-left: 24px;
    margin-right: 24px;
  }
}
