.contactUsContainer {
  margin-top: 48px;
  margin-bottom: 50px;
}

.contactUsContainer .customContactRow {
  margin-top: 48px;
}

.contactUsContainer .textContainer {
  margin: auto;
  width: 120px;
}

.contactUsContainer .photo {
  height: 120px;
  width: 120px;
  object-fit: scale-down;
}

.contactUsContainer .photoDesktop {
  height: 154.67px;
  width: 154.67px;
  object-fit: scale-down;
}

.contactUsContainer .textContainer p{
  margin-block-start: unset;
  margin-block-end: unset;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  color: #667175;
}

.h2ContactUs{
  margin-block-end: unset;
  font-family: 'Open Sans';
  font-size: 22px;
  line-height: 24px;
  color: #181818;
}

.h2ContactUs.ar {
  direction: rtl;
}

.pContactUs{
  margin-block-start: unset;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 13.5px;
  line-height: 28px;
  color: #667175;
}

.pContactUs.ar {
  direction: rtl;  
}

.contactFooter {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  height: 158px;
  width: -webkit-fill-available;
  padding-left: 31px;
  margin-top: 79px;
  background-color: #F0F0F0;
  z-index: 1 
}

.contactFooter .copyRContainer {
  position: relative;
  display: inline-block;
  justify-content: center;
  vertical-align: middle;
  height: 48px;
  width: 100%;
}

.contactFooter .copyRContainer .shockoeMonster{
  position: absolute;
  bottom: 48px;
  height: 55px;
  width: 95px;
  z-index: 0;
}

.contactFooter .copyRContainer a{
  text-decoration: unset !important;
}

.contactFooter .copyRContainer .pFooterContactUs {
  margin-top: 16px;
  font-family: 'Open Sans';
  font-size: 10px;
  line-height: 14px;
  color: #787878;
}

.contactFooter .logoContainer {
  position: relative;
  display: inline-block;
  height: 48px;
  width: 48px;
  margin-top: -70px;
}

.contactFooter .logoContainer .imageFotterContactUs {
  height: 100%;
  object-fit: scale-down;
}

/* Section custom css hubspot */

.hs-form label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 28px;
  color: #F46C00;
}

.hs-form .hs-error-msgs label{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 28px;
  color: red;
}

.hs-form input[type="text"],
.hs-form input[type="password"], 
.hs-form input[type="datetime"], 
.hs-form input[type="datetime-local"], 
.hs-form input[type="date"], 
.hs-form input[type="month"], 
.hs-form input[type="time"], 
.hs-form input[type="week"], 
.hs-form input[type="number"], 
.hs-form input[type="email"], 
.hs-form input[type="url"], 
.hs-form input[type="search"], 
.hs-form input[type="tel"], 
.hs-form input[type="color"],
.hs-form input[type="file"],
.hs-form textarea,
.hs-form select {
  outline: none;
  padding: unset !important;
  background: transparent;
  border-top: transparent !important;
  border-left: transparent !important;
  border-right: transparent !important;
  border-bottom: 1px solid #C1C7C9 !important;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 28px;
  color: #181818;
}

body .hs-button.primary,
body input[type="submit"],
body input[type="button"] {
  /* text-align: right !important; */
  cursor: pointer;
  outline: none;
  margin-top: 40px;
  height: 48px;
  width: 218px;
  border: 3px solid #F46C00 !important;
  background-color: #FFFFFF !important;
  box-sizing: border-box;

  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #F46C00 !important;
  -webkit-appearance: unset !important;
}

.contactUsContainer ul {
  list-style-type: none;
  padding-inline-start: unset;
}

/* End Section custom css hubspot */

@media(min-width: 768px) {
  .contactFooter {
    justify-content: center;
    height: 48px;
    padding-left: 45px;
  }

  .contactFooter .copyRContainer {
    width: unset;
  }

  .contactFooter .copyRContainer .shockoeMonster{
    left: -10px;
  }

  .contactFooter .logoContainer {
    margin-top: unset;
  }

  .contactUsContainer {
    margin-bottom: unset;
  }

  .contactUsContainer .customContactRow {
    margin-top: unset;
  }

  .contactUsContainer .customContactCol {
    margin-left: 102px !important;
  }

  .contactUsContainer .actions {
    text-align: right !important;
  }
}

@media(min-width: 1024px) {
  .contactFooter {
    margin-top: 140px;
  }
}

@media(min-width: 1300px) {
  .h2ContactUs{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
  }

  .pContactUs{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  .contactUsContainer .textContainer{
    width: unset;
  }

  .contactUsContainer .textContainer p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #667175;
  }

  .contactUsContainer .textContainer p span{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #181818;
  }
}