html, body {
  max-width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.homeContainer {
  margin-left: 54px;
  margin-right: 54px;
}

@media only screen and (max-width: 320px) {
  .homeContainer {
    margin-left: 24px;
    margin-right: 24px;
  }
}

.homeContainer .logo {
  margin-top: 88px;
}

@media only screen and (max-height: 605px) {
  .homeContainer .logo {
    margin-top: 35px;
  }

  .homeFooter {
    margin-top: 65px;
    background-image: url('./../../assets/images/DotGrid_expand.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 160px;
  }

}

.homeContainer .logo-desktop {
  margin-top: 205.81px;
}

.homeContainer .peeps {
  margin-top: 48px;
}

.homeContainer .homeDescriptionContainer {
  height: 54px;
  width: 268px;
  margin: auto;
}

.homeContainer .homeDescriptionContainer .homeDescription {
  text-align: center;
  font-family: 'Poppins';
  font-style: 'normal';
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.homeContainer .homeDescriptionContainer .homeDescription.ar {
  direction: rtl;
}

.homeContainer .homeDescriptionContainer .homeDescription span {
  color: #F46C00;
  font-weight: 600;
}

.homeFooter {
  margin-top: 79px;
  background-image: url('./../../assets/images/DotGrid_expand.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 160px;
}

.homeFooterDesktop {
  margin-top: -85px;
  margin-bottom: 120px;
  background-image: url('./../../assets/images/DotGrid-Desktop.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 160px;
  z-index: 0;
}

.homeFooter.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}
.homeFooter.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.strategySectionTitle {
  margin-top: 120px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 96px;
  text-align: center;
  color: #181818;
}

.strategySectionTitle.ar {
  direction: rtl;
}

.productsTitle {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #373D3F;
}

.productsTitle.ar {
  direction: rtl;
}

.marginBottom {
  margin-bottom: 44px;
}

.cardsContainer {
  display: flex;
  justify-content: center;
}

.cardsContainerProducts {
  display: flex;
  justify-content: center;
}

.cardsContainerCaseStudies {
  display: flex;
  justify-content: center;
}

#Expertise .alice-carousel__dots {
  margin-top: 0 !important;
}

#whitePapers {
  background: #F2F3F4;
  padding-top: 25px;
  padding-bottom: 72px;
}

@media(min-width: 768px) {
  .homeContainer .homeDescriptionContainer {
    height: 54px;
    width: unset;
    margin: auto;
  }

  .problemFooter {
    display: none;
  }
}

@media(min-width: 1024px) {
  #products {
    padding-left: 130px;
    padding-right: 130px;
  }

  #products .customContainer {
    padding-left: unset !important;
    padding-right: unset !important;
  }

  #whitePapers {
    background: #FBFBFB;
  }

  .productsTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    text-align: center;
  }
}

@media(min-width: 1366px) {
  #products {
    padding-left: 370px !important;
    padding-right: 370px !important;
  }

  #products .customContainer {
    padding-left: unset !important;
    padding-right: unset !important;
  }
}