.menuButtonHamburguer {
  position: fixed;
  top: 24px;
  right: 24px;
  height: 40px;
  width: 40px;
  outline: none;
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-user-select: none; /* for button */
   -webkit-appearance: button; /* for input */
     -moz-user-select: none;
      -ms-user-select: none;
  z-index: 2;
}

.menuButtonClose {
  position: absolute;
  top: 24px;
  right: 24px;
  height: 40px;
  width: 40px;
  outline: none;
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-user-select: none; /* for button */
   -webkit-appearance: button; /* for input */
     -moz-user-select: none;
      -ms-user-select: none;
  z-index: 10000;
}

#presentationMenuDesktop {
  padding-bottom: 30px;
  width: 375px;
}

#presentationMenuDesktop h3{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #181818;
}

#presentationMenuDesktop span{
  padding-left: 24px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

#presentationMenuDesktop a:hover, #presentationMenuDesktop ul a li:hover {
  background-color: #F46C00 !important;
  color: white !important;
}

#presentationMenuDesktop a:hover, #presentationMenuDesktop ul li:hover {
  background-color: #F46C00 !important;
  color: white !important;
}

#presentationMenuDesktop ul a{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #373D3F;
  text-decoration:none;
}