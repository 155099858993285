
.footerContainer {
  position:fixed;
  display: flex;
  height: 58.33px;
  text-align: left;    
  bottom: 0;
  width: 100%;
  z-index: 1400;
}

.footerContainer .barLeft {
  position: relative;
  display: inline-block;
  /*border-top: 2px solid rgba(55, 61, 63, 0.2);*/
  background: #FFFFFF;
  width: -webkit-fill-available;
  -webkit-box-shadow: 0px 0px 2px rgba(50, 61, 63, 0.2);
  -moz-box-shadow: 0px 0px 2px rgba(50, 61, 63, 0.2);
  box-shadow: 0px 0px 2px rgba(50, 61, 63, 0.2);
}

.footerContainer .barRight {
  position: relative;
  display: inline-block;
  border-left: 1px solid #FFFFFF;
  background: radial-gradient(circle at 35px 0,  transparent 49%, rgba(241, 243, 244, 0.5) 50%, #FFFFFF 52%);
  width: 89px;
}

.footerLogoContainer.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}
.footerLogoContainer.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}

.footerLogoImg {    
  width: 32px;
  height: 32px;
  margin-top: 13px;
  margin-left: 16px;
  margin-bottom: 13px;
}

@media (min-width: 768px) {
  .footerContainer {
    display: none;
  }
}