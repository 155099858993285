.problemsContainer {
  padding-bottom: 40px;
  background-color: #F2F3F4;
}

.problemsWrapper h3 {
  padding-top: 40px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #373D3F;
}

.problemsWrapper h3.ar {
  direction: rtl;
}

.problemsWrapper .contentText {
  margin: auto;
  width: 88px;
}

.problemsWrapper p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #181818;
  margin-top: -6px;
  margin-bottom: 24px;
}

.problemsWrapper p.ar {
  direction: rtl;
}

.problemsSubtitle {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: #667175;
}

.problemsSubtitle span {
  color: #F46C00;
}

.problemsSubtitle.ar {
  direction: rtl;
}

.problemsWrapper a {
  text-decoration: none;
}

@media(max-width: 320px) {
  .customCol {
    padding-left: unset !important;
    padding-right: unset !important;
  }
}

@media(min-width: 768px) {
  .problemsWrapper {
    background-color: #FBFBFB;
    padding-left: 200px;
    padding-right: 200px;
  }

  .problemsContainer {
    background-color: unset;
  }
}

@media(min-width: 1024px) {
  .problemsWrapper {
    padding-left: 230px;
    padding-right: 230px;
  }

  .problemsWrapper .customRow {
    margin-left: -70px !important;
    margin-right: -70px !important;
  }

  .problemsWrapper h3 {
    padding-top: 120px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    text-align: center;
  }

  .problemsContainer {
    background-color: unset;
    padding-bottom: 120px;
  }

  .problemsWrapper .contentText {
    margin: auto;
    width: unset;
  }

  .problemsWrapper p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }
}

@media(min-width: 1366px) {
  .problemsWrapper .customRow {
    margin-left: -70px !important;
    margin-right: -70px !important;
  }
}