#whitePapers .alice-carousel__dots-item {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  margin-left: 10px;
  background-color:#C1C7C9 !important;
  opacity: 0.25;
}

#whitePapers .alice-carousel__dots-item.__active {  
  width: 16px;
  height: 16px;
  background-color:#C1C7C9 !important;
  opacity: 1;
}

#Expertise .alice-carousel__dots-item {
  color: #C1C7C9 !important;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

#Expertise .alice-carousel__dots , 
#strategyComponent .alice-carousel__dots, 
#products .alice-carousel__dots, 
#Emerging .alice-carousel__dots,
#whitePapers .alice-carousel__dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px !important;
}

#Expertise .alice-carousel__dots-item.__active, #strategyComponent .alice-carousel__dots-item.__active{  
  width: 16px;
  height: 16px;  
}

#products .alice-carousel__dots-item {
  color: #C1C7C9;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

#products .alice-carousel__dots-item.__active {  
  width: 16px;
  height: 16px;
}

.alice-carousel__dots{
  margin: unset !important;
}

.alice-carousel__dots-item{
  background-color:#C1C7C9 !important;
  opacity: 0.25;
}

.alice-carousel__dots-item.__active {
  background-color:#C1C7C9 !important;
  opacity: 1;
}
