.promptContainer {
  position: fixed;
  bottom: 0;
  margin: auto;
  padding-top: 29px;
  padding-left: 24px;
  padding-right: 24px;
  height: 128px;
  background-color: #532E61;
  z-index: 1500;
}

.promptContainer p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #FFFFFF;
  mix-blend-mode: normal;
}

.promptContainer p.ar {
  direction: rtl;
}

.promptContainer button {
  background: transparent;
  outline: none;
  border: unset;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.promptContainer button.ar {
  direction: rtl;
}

.promptContainer .contentButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.promptContainer .helpText {
  display: inline-block;
  width: 250px;
}
