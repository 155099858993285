.circleBase {
  border-radius: 50%;
  z-index: 10000;
  outline: none;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
}

.type3 {
  width: 58.33px;
  height: 58.33px;
  background: #F46C00;
  border: 8px solid transparent;
  position: relative;
  margin-left: 8%;
  margin-top: -43%;
  display: flex;
}

.btnImage {
  display: inline-block;
  position: absolute;
  margin: auto;
  height: 25px;
  width: 25px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.MuiDrawer-paperAnchorBottom{
  bottom: 20px !important;
}

#presentationMenu {
  padding-bottom: 50px;
}

.MuiDrawer-paperAnchorBottom{
  bottom: 0 !important;
}

#presentationMenu a:active, #presentationMenu ul a li:active {
  background-color: #F46C00 !important;
  color: white !important;
}

#presentationMenu a:active, #presentationMenu ul li:active {
  background-color: #F46C00 !important;
  color: white !important;
}

#presentationMenu h3
{
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;    
  text-transform: uppercase;
  color: #667175;
  margin-left: 16px;
}

#presentationMenu ul a{
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #373D3F;
  text-decoration:none;
}

@media (min-width: 768px) {
  .circleBase {
    display: none;
  }
}