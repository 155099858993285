.cardWrapper {
  position: relative;
  cursor: pointer;
  width: 277px;
  height: 471px;
  box-shadow: 0px 2px 8px rgba(55, 61, 63, 0.2);
  margin-bottom: 5px;
  background: #FFFFFF;
}

.cardWrapper .phoneCover {
  position: absolute;
  height: 213px;
  width: 109px;
  top: 40px;
  right: 30px;
  z-index: 0;
}

.cardWrapper .cover {
  background-repeat: no-repeat;
  background-size: cover;
  height: 160px;
  z-index: -1;
}

.cardWrapper .cover--0 {
  background-image: url('./../../assets/images/Mask_arrow.png');
}

.cardWrapper .cover--1 {
  background-image: url('./../../assets/images/Mask_oneok.png');
}

.cardWrapper .cover--2 {
  background-image: url('./../../assets/images/Mask_jb_hunt.png');
}

.cardWrapper .cover--3 {
  background-image: url('./../../assets/images/Mask_acm.png');
}

.cardWrapper .cover--4 {
  background-image: url('./../../assets/images/Mask_vacu.png');
}

.cardWrapper .cover--5 {
  background-image: url('./../../assets/images/Mask_winn.png');
}

.cardWrapper .cover--6 {
  background-image: url('./../../assets/images/Mask_american_woodmark.png');
}

.cardWrapper .cover--7 {
  background-image: url('./../../assets/images/Mask_valacta.png');
}

.cardWrapper .cover--8 {
  background-image: url('./../../assets/images/Mask_farmers.png');
}

.cardWrapper .cover--9 {
  background-image: url('./../../assets/images/Mask_dominion.png');
}

.cardWrapper .cover--10 {
  background-image: url('./../../assets/images/Mask_floorco.png');
}

.cardWrapper .cover--11 {
  background-image: url('./../../assets/images/Mask_playart.png');
}

.cardWrapper .cover--12 {
  background-image: url('./../../assets/images/Mask_skafos.png');
}

.cardWrapper .logo{
  margin-top: 8px;
}

.cardWrapper .logo.long{
  margin-top: -12px;
}

.cardWrapper .logo-back{
  margin-top: 24px;
  z-index: 1;
}

.cardWrapper .dashboard-tablet{
  position: absolute;
  height: 168px;
  /*width: 234.91px;*/
  top: 40px;
  z-index: 1;
}

.cardWrapper .dashboard-phone{
  position: absolute;
  height: 213px;
  width: 109px;
  top: 40px;
  right: 30px;
  z-index: 1;
}

.cardWrapper .dashboard-phone-gif{
  position: absolute;
  height: 189px;
  width: 87px;
  top: 52px;
  right: 43px;
  z-index: 1;
}

.cardWrapper .dotgrid {
  object-fit: fill;
  width: 100%;
  z-index: -1;
}

.cardWrapper .dotgrid-footer {
  position: absolute;
  bottom: 0;
  object-fit: fill;
  width: 100%;
  z-index: -1;
}

.cardWrapper .dotgrid-back {
  position: absolute;
  top: 9px;
  right: 0;
  object-fit: scale-down;
  z-index: -1;
}

.cardWrapper .dotgrid-back-footer {
  position: relative;
  right: 0;
  object-fit: scale-down;
  z-index: -1;
}

.cardWrapper .dotgridContainer {
  position: relative;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
}

.cardWrapper p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #181818;
}

.cardWrapper p span{
  color: #F77F2B;
}

.cardWrapper p.ar {
  text-align: right;
  direction: rtl;
}

.cardWrapper ul {
  list-style-type: disc;
  padding-inline-start: 32px;
}

.cardWrapper ul li {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #181818;
}

.cardWrapper ul.ar {
  text-align: right;
  direction: rtl;
}

.cardWrapper .quote {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #667175;
}

.cardWrapper .quote.ar {
  direction: rtl;
}

.cardWrapper .infoContainer {
  position: absolute;
  bottom: 17px;
  z-index: 1;
}

.cardWrapper .left {
  left:0;
}

.cardWrapper .right {
  right: 0;
}

.cardWrapper .infoContainer .info {
  width: 220px;
  height: 84px;
}

.cardWrapper .infoContainer .info.ar {
  direction: rtl;
}

.cardWrapper .infoContainer .info.ar.small h3{
  font-size: 15px;
}

.cardWrapper .infoContainer .info.ar.small p{
  font-size: 12px;
}

.cardWrapper .infoContainer .info h3{
  margin-block-start: unset;
  margin-block-end: unset;
  -webkit-margin-before: unset;
  -webkit-margin-after: unset;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
  color: #FFFFFF;
}

.cardWrapper .infoContainer .info p{
  margin-block-start: unset;
  margin-block-end: unset;
  -webkit-margin-before: unset;
  -webkit-margin-after: unset;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
}

.cardWrapper .footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
}

.react-card-flip {
  margin-left: 5px;
  margin-bottom: 10px;
}

.cardRotateLeft{
  position: absolute;
  left: 8px;
  bottom: 8px;
  width: 24px;
  height: 16px;
}

.cardRotateRight{
  position: absolute;
  right: 8px;
  bottom: 8px;
  width: 24px;
  height: 16px;
}

@media only screen and (max-width: 360px) {
  .smallScreenFlip{
    margin-right: 10px !important;
  }
}

@media (min-width: 768px) {
  .cardWrapper {
    margin-left: 24px;
    margin-right: 24px;
  }
}
