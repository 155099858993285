.productSuccessWrapper {
  position: relative;
  justify-content: center;
  margin-top: 120px;
  margin-bottom: 120px;
}

.productSuccessWrapper h2 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  color: #181818;
}

.productSuccessWrapper h2.ar {
  direction: rtl;
}

.productSuccessWrapper .textBold {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #373D3F;
  text-transform: lowercase;
}

.productSuccessWrapper .textBold.ar {
  direction: rtl;
}

.productSuccessWrapper .textBold:first-letter{
  text-transform: capitalize;
}

.productSuccessWrapper .textNormal {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #667175;
}

.productSuccessWrapper .textNormal.ar {
  direction: rtl;
}

.productSuccessWrapper .textNormal span {
  font-weight: 600;
}

.productSuccessWrapper .productCirclesWrapper  {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 56px;
  z-index: 1;
}

.productSuccessWrapper .productCirclesWrapper hr{
  position: absolute;
  width: 810px;
  height: 0px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #F46C00;
  z-index: 0;
}

.productSuccessWrapper .productCirclesWrapper .chevronLeft{
  position: relative;
  justify-content: center;
  margin-top: 139.5px;
  width: 40px;
  height: 40px;
  z-index: 1;
}

.productSuccessWrapper .productCirclesWrapper .chevronRight{
  position: relative;
  justify-content: center;
  margin-top: 139.5px;
  width: 40px;
  height: 40px;
  z-index: 1;
}

.productSuccessWrapper .productCirclesWrapper .productCircleContainer {
  position: relative;
  justify-content: center;
  margin-left: 61px;
  margin-right: 61px;
}

.productSuccessWrapper .productCirclesWrapper .productCircleContainer .productCircle {
  position: relative;
  justify-content: center;
  vertical-align: middle;
  height: 305px;
  width: 305px;
  background: #FBFBFB;
  border-radius: 50%;
  border: 4px solid #F46C00;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.productSuccessWrapper .productCirclesWrapper .productCircleContainer .productCircle .textCircleContainer {
  position: absolute;
  margin: auto;
  height: 144px;
  width: 220px;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.productSuccessWrapper .productCirclesWrapper .productCircleContainer .productCircle .textCircleContainer .textCircle {
  margin-block-start: unset;
  margin-block-end: unset;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #F46C00;
}

.productSuccessWrapper .productCirclesWrapper .productCircleContainer .textFooterContainer {
  height: 54px;
  width: 305px;
}

.productSuccessWrapper .productCirclesWrapper .productCircleContainer .textFooterContainer .textFooter {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #667175;
}

.productSuccessWrapper .productCirclesWrapper .productCircleContainer .textFooterContainer .textFooter.ar {
  direction: rtl;
}

.productSuccessWrapper .productCirclesWrapper .productCircleContainer .textFooterContainer .textFooter span {
  font-weight: bold;
}