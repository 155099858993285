.productWrapper {
  display: inline-block;
  height: 497px;
  width: 413px;
  margin-left: 12px;
  margin-right: 12px;
  padding-left: 24px;
  padding-right: 24px;
}

.productWrapper.ar {
  direction: rtl;
}

.productTitle {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;    
  text-align: left;
  color: #FBFBFB;
  margin-top: 88px;
}

.productTitle.ar{
  text-align: right;
  direction: rtl;
}

.productTitle-p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #FBFBFB;
  margin-block-end: unset;
}

.productTitle-p span {
  font-weight: bold;
}

.productTitle-ul  {
  margin-block-start: unset !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #FBFBFB;
  list-style-type: disc;
}

.productTitle-ul span {
  font-weight: bold;
}

#productComponent .alice-carousel__dots-item.__active{
  background-color:#FFFFFF !important;
  opacity: 1;
}