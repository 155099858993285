.productSuccessTitle {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;    
    text-align: center;
}

.productTextContainer {
  position: relative;
  height: 54px;
  width: 327px;
  margin: auto;
}

.productText {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;    
    text-align: center;    
    color: #667175;
}

.productText.ar {
  direction: rtl;
}

.productTextChart {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;    
}

.productTextChart p {
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 1px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;        
    text-align: center;                
    color: #F46C00;
}

.tapHere {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal !important;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-decoration-line: underline;
    
}

.divTap {
    padding-top: 16px !important;
}

.dot {
  display: inline-block;
  position: absolute;
  height: 261px;
  width: 262px;
  background-color: #FBFBFB;
  border-radius: 50%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  top: 50%;
  left: 50.2%;
  transform: translate(-50%, -50%);
}

@media (max-width: 320px) {
  .productTextContainer {
    width: 100%;
  }
}

@media screen and (max-width: 380px) {
  .dot {
    height: 241px;
    width: 242px;
    top: 49.9%;
    left: 50.2%;
  }
}

@media (min-width: 768px) {
  .dot {
    top: 50%;
    left: 50.1%;
  }
}